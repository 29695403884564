<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :mask-closable="false"
    title="详情"
    width="600px"
    @cancel="$emit('update:visible', false)"
  >
    <a-descriptions :column="2">
      <template #title>
        <div class="iss-account">
          <a-avatar
            v-if="initValue.avatar"
            :src="setAvatar(initValue.avatar)"
            :size="104"
            class="mb-8"
          />
          <a-avatar v-else :size="104" class="iss-avatar mb-8">
            {{ initValue.name }}
          </a-avatar>
          <div class="account-name">{{ initValue.name }}</div>
          <div class="account-desc">
            {{ initValue.workDescribe || '这家伙很懒，什么都没留下～' }}
          </div>
        </div>
      </template>
      <a-descriptions-item>
        <template #label>
          <span><mobile-outlined class="mr-5" />手机号</span>
        </template>
        {{ initValue.mobile }}
      </a-descriptions-item>
      <a-descriptions-item>
        <template #label>
          <span>
            <man-outlined class="mr-5" v-if="initValue.sex?.code === 'M'" />
            <woman-outlined
              class="mr-5"
              v-else-if="initValue.sex?.code === 'W'"
            />
            <star-outlined class="mr-5" v-else />性别
          </span>
        </template>
        {{ initValue.sex?.desc }}
      </a-descriptions-item>
      <a-descriptions-item>
        <template #label>
          <span><mail-outlined class="mr-5" />邮箱</span>
        </template>
        {{ initValue.email }}
      </a-descriptions-item>
      <a-descriptions-item>
        <template #label>
          <span>
            <check-circle-outlined v-if="initValue.status" class="mr-5" />
            <stop-outlined v-else class="mr-5" />状态
          </span>
        </template>
        {{ initValue.status ? '启用' : '禁用' }}
      </a-descriptions-item>
      <a-descriptions-item>
        <template #label>
          <span><idcard-outlined class="mr-5" />岗位</span>
        </template>
        {{ initValue.stationName }}
      </a-descriptions-item>
      <a-descriptions-item>
        <template #label>
          <span><apartment-outlined class="mr-5" />组织</span>
        </template>
        {{ initValue.orgName }}
      </a-descriptions-item>
      <a-descriptions-item>
        <template #label>
          <span><copyright-outlined class="mr-5" />创建时间</span>
        </template>
        {{ initValue.createTime }}
      </a-descriptions-item>
      <a-descriptions-item>
        <template #label>
          <span><format-painter-outlined class="mr-5" />更新时间</span>
        </template>
        {{ initValue.updateTime }}
      </a-descriptions-item>
      <a-descriptions-item>
        <template #label>
          <span><login-outlined class="mr-5" />最后登录时间</span>
        </template>
        {{ initValue.lastLoginTime }}
      </a-descriptions-item>
      <a-descriptions-item>
        <template #label>
          <span><safety-outlined class="mr-5" />密码过期时间</span>
        </template>
        {{ initValue.passwordExpireTime }}
      </a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script>
import { Avatar, Descriptions } from 'ant-design-vue';
import {
  ManOutlined,
  WomanOutlined,
  StarOutlined,
  MobileOutlined,
  MailOutlined,
  ApartmentOutlined,
  IdcardOutlined,
  CheckCircleOutlined,
  StopOutlined,
  LoginOutlined,
  CopyrightOutlined,
  SafetyOutlined,
  FormatPainterOutlined,
} from '@ant-design/icons-vue';
import { setAvatar } from '@/utils';

export default {
  components: {
    AAvatar: Avatar,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    ManOutlined,
    WomanOutlined,
    StarOutlined,
    MobileOutlined,
    MailOutlined,
    ApartmentOutlined,
    IdcardOutlined,
    CheckCircleOutlined,
    StopOutlined,
    LoginOutlined,
    CopyrightOutlined,
    SafetyOutlined,
    FormatPainterOutlined,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    initValue: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return { setAvatar };
  },
};
</script>

<style lang="less" scoped>
.iss-account {
  text-align: center;
  .account-name {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 2px;
  }
  .account-desc {
    color: @text-color;
    font-size: @font-size-base;
    font-weight: normal;
  }
}
</style>
